@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-navy;
}

strong {
  @apply font-semibold;
}

a {
  @apply font-semibold cursor-pointer opacity-90;
}
a:hover {
  @apply opacity-100 underline decoration-1 decoration-navy underline-offset-2;
}

.markdown-list {
  @apply ml-12 space-y-3 list-decimal marker:text-gray-400;
  .markdown-list {
    @apply ml-5;
  }
}

.dark-bg {
  a:hover {
    @apply decoration-white/30;
  }
}

.mux-player-wrapper {
  @apply mt-4 rounded-md overflow-hidden relative;
  line-height: 0;
}

.offscreen {
  visibility: hidden;
  position: absolute;
  top: -10000px;
  left: -10000px;
}

.container-shadow {
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.25);
}

.mirror {
  transform: scaleX(-1);
}

button {
  @apply font-medium bg-white border border-transparent rounded-full leading-none shadow-md opacity-95 text-black;
  padding: 0.8rem 0.9rem 0.7rem;
}
button.small {
  @apply text-sm;
  padding: 0.5rem 0.7rem 0.4rem;
}
button[type='submit'] {
  @apply bg-white;
}
button:hover {
  @apply shadow-lg opacity-100;
}
button:active {
  @apply shadow-lg opacity-90;
}
button[disabled] {
  @apply opacity-25;
}
button.secondary {
  @apply bg-transparent text-white border border-white shadow-none font-medium;
}
.footer {
  @apply text-white;
  button[disabled] {
    @apply opacity-15 bg-transparent border border-black shadow-none;
  }
}

/* https://codepen.io/claviska/pen/MoLWbj */
.spinner {
  /* Spinner size and color */
  width: 1.1rem;
  height: 1.1rem;

  @apply border-t-navy border-l-navy;

  /* Additional spinner styles */
  animation: spinner 1s linear infinite;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
}
.spinner.foreground {
  @apply border-t-white border-l-white;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

fieldset {
  @apply w-full;
  input {
    @apply w-full px-2 py-1 border border-warm-gray rounded;
  }
}

/** Products **/

.product {
  @apply flex items-center justify-center max-w-xs h-32 border shadow rounded-md font-medium cursor-pointer;
}
.product:hover {
  @apply shadow-lg;
}

/** Utils */

.upload-animation {
  @apply transition-opacity duration-300 delay-3000;
}
